import { useEffect, useState, memo } from "react";
import { Bar } from "react-chartjs-2";
import { http } from "../helper";
import { BaseUrl } from "../common/config";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";

Chart.register(CategoryScale);

export const BarChart = memo(({ }) => {
    var currentTime = new Date();
    var currentyear = currentTime.getFullYear();
    const [reportType, setReportType] = useState("weekly");
    const [period, setPeriod] = useState("2024-2025");
    const [status, setStatus] = useState("all");
    const [isLoading, setIsLoading] = useState(true);

    // Custom plugin to display "No data found"
    const noDataPlugin = {
        id: "noDataPlugin",
        beforeDraw: (chart) => {
            const { datasets } = chart.data;
            const hasData = datasets.some((dataset) => dataset.data.length > 0);

            if (!hasData) {
                const ctx = chart.ctx;
                const { width, height } = chart;

                ctx.save();
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.font = "20px Arial";
                ctx.fillStyle = "#eb9f9d";
                ctx.fillText("Sorry, no data found for your filtered search.", width / 2, height / 2);
                ctx.restore();
            }
        },
    };

    const [topSellerChartData, setTopSellerChartData] = useState({
        labels: [],
        datasets: [
            {
                label: "Top Selling Products",
                data: [],
                backgroundColor: ["rgba(252,194,84,1)", "#f4ecec"],
                borderColor: "#e6e6e6",
                borderWidth: 2,
            },
        ],
    });

    const priceFormat = (price) => {
        return price.toLocaleString("en-US", {
            style: "currency",
            currency: "GBP",
            minimumFractionDigits: 2,
        });
    };
    const quarterNames = { 1: "Sep-Nov", 2: "Dec-Feb", 3: "Mar-May", 4: "Jun-Aug" };
    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    useEffect(() => {
        if (reportType !== 'quarterly') {
            let URL3 = BaseUrl + "admin/getrevenuereport";
            http
                .post(URL3, { reportType: reportType, period: period, status: status })
                .then((response) => {
                    setIsLoading(false);
                    const chartData = response.data.data.filter((item) => item.amount > 0)
                    //console.log(chartData,'chartData');
                    setTopSellerChartData({
                        labels: chartData.map((item) =>
                            item._id.month !== undefined
                                ? item._id.month + "/" + item._id.year
                                : item._id.year !== undefined ? item._id.year : item._id.dateString !== undefined ? item._id.dateString + ' ' + dayNames[item._id.dayOfWeek - 1] : item._id
                        ),
                        datasets: [
                            {
                                label: "Total Revenue (£)",
                                data: chartData.map((item) => item.amount.toFixed(2)),
                                backgroundColor: ["#8dc5e4", "#1b81bc"],
                                borderColor: "#E5E5E5",
                                borderWidth: 0,
                                barThickness: 40,
                                maxBarThickness: 60,
                                borderRadius: 4,

                            },
                        ],
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            let URL3 = BaseUrl + "admin/quarterlyreport";
            http
                .post(URL3, { period: period, status: status })
                .then((response) => {
                    setIsLoading(false);
                    const chartData = response.data.data.filter((item) => item.amount > 0)
                    setTopSellerChartData({
                        labels: chartData.map((item) => quarterNames[item._id]),
                        datasets: [
                            {
                                label: "Total Revenue (£)",
                                data: chartData.map((item) => item.amount.toFixed(2)),
                                backgroundColor: ["#8dc5e4", "#1b81bc"],
                                borderColor: "#E5E5E5",
                                borderWidth: 0,
                                barThickness: 40,
                                maxBarThickness: 60,
                                borderRadius: 4,

                            },
                        ],
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [reportType, period, status]);

    const changeHandler = (e) => {
        setReportType(e.target.value);
    };

    const changeStatusHandler = (e) => {
        setStatus(e.target.value);
    };
    const changePeriodHandler = (e) => {
        setPeriod(e.target.value);
    };
    const options = [];

    for (let i = currentyear; i >= 2023; i--) {
        const year = i;
        options.push(<option value={year + "-" + (year + 1)}>{year + "-" + (year + 1)}</option>);
    }
    let loadingbuffer = isLoading ? 'loading-buffer' : '';
    return (

        <div className={`chart-container chart-revenue ${loadingbuffer}`}>
            <div className="chart-Hdr">

                <h2 style={{ textAlign: "left" }}>
                    Revenue Analysis{"  "}
                </h2>
                <div className="chart-fltr">
                    <select style={{ width: '170px' }}
                        value={status}
                        onChange={(e) => changeStatusHandler(e)}

                    > <option value={"all"}>All</option>
                        <option value={"New Order"}>New Order</option>
                        <option value={"Acknowledged"}>Acknowledged</option>
                        <option value={"Invoiced"}>Invoiced</option>
                        <option value={"Part Desp.  Inv."}>Part Desp. Inv.</option>
                        <option value={"Acknl  Being Picked"}>Acknl Being Picked</option>

                    </select>
                    <select style={{ width: '150px' }}
                        value={reportType}
                        onChange={(e) => changeHandler(e)}

                    >  <option value={"weekly"}>Current Week</option>
                        <option value={"7days"}>Past 7 days</option>
                        <option value={"monthly"}>Monthly</option>
                        <option value={"quarterly"}>Quarterly</option>
                        <option value={"yearly"}>Yearly</option>
                    </select>
                    {(reportType === 'monthly' || reportType === 'quarterly') && <select style={{ width: '170px' }}
                        value={period}
                        onChange={(e) => changePeriodHandler(e)}

                    >
                        {options}
                    </select>}
                </div>

            </div>
            {topSellerChartData && (
                <Bar
                    data={topSellerChartData}
                    plugins={[noDataPlugin]}
                    options={{
                        scales: {
                            y: {
                                grid: {
                                    color: 'rgba(247, 243, 241, 0.8)',
                                },
                                ticks: {
                                    color: 'rgba(207, 208, 211, 1)',
                                }
                            },
                            x: {
                                grid: {
                                    color: 'rgba(247, 243, 241, 0.8)'
                                },
                                ticks: {
                                    color: 'rgba(207, 208, 211, 1)',
                                    callback: function (value, index, ticks) {
                                        const label = this.getLabelForValue(value); // Get the label for the current tick
                                        if (typeof label === 'string' && label.includes(" ")) {
                                            const [date, dayOfWeek] = label.split(" "); // Split by the comma separator
                                            return [dayOfWeek, date];  // Return the label with a newline
                                        }
                                        return label; // Single-line label for other cases
                                    },
                                }
                            }
                        },
                        layout: {
                            padding: {
                                top: 20,
                                right: 0,
                                bottom: 0,
                                left: 0,
                            }
                        },
                        plugins: {
                            title: {
                                display: false,
                                text: "Revenue Analysis",
                            },
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                titleColor: 'rgba(220, 222, 226, 1)',
                                bodyColor: 'rgba(220, 222, 226, 1)'
                            },
                            datalabels: {
                                display: false,
                                font: {
                                    size: 11,
                                    weight: '600',
                                },
                                padding: 5,
                                anchor: 'end',
                                align: "top",
                                formatter: function (value) {
                                    return "£" + value;
                                    //return priceFormat(value);
                                },

                            }
                        },
                    }}
                />
            )}
        </div>
    );
});
