import { useEffect, useState, memo } from "react";
import { Bar } from "react-chartjs-2";
import { http } from "../helper";
import { BaseUrl } from "../common/config";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";

Chart.register(CategoryScale);

export const TopRevenueChart = memo(({ }) => {
    var currentTime = new Date();
    var currentyear = currentTime.getFullYear();
    const [period, setPeriod] = useState("2024-2025");
    const [isLoading, setIsLoading] = useState(true);
    const [reportType, setReportType] = useState("top");

    // Custom plugin to display "No data found"
    const noDataPlugin = {
        id: "noDataPlugin",
        beforeDraw: (chart) => {
            const { datasets } = chart.data;
            const hasData = datasets.some((dataset) => dataset.data.length > 0);

            if (!hasData) {
                const ctx = chart.ctx;
                const { width, height } = chart;

                ctx.save();
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.font = "16px Arial";
                ctx.fillStyle = "#eb9f9d";
                ctx.fillText("Sorry, no data found for your filtered search.", width / 2, height / 2);
                ctx.restore();
            }
        },
    };

    const [topSellerChartData, setTopSellerChartData] = useState({
        labels: [],
        datasets: [
            {
                label: "Top Selling Products",
                data: [],
                backgroundColor: ["rgba(252,194,84,1)", "#f4ecec"],
                borderColor: "#e6e6e6",
                borderWidth: 2,
            },
        ],
    });

    const priceFormat = (price) => {
        return price.toLocaleString("en-US", {
            style: "currency",
            currency: "GBP",
            minimumFractionDigits: 2,
        });
    };
    //const quarterNames = { 1: "Sep-Nov", 2: "Dec-Feb", 3: "Mar-May", 4: "Jun-Aug" };
    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    useEffect(() => {
        let URL3 = BaseUrl + "admin/gettoprevenuereport";
        http
            .post(URL3, { period: period, reportType: reportType })
            .then((response) => {
                setIsLoading(false);
                const chartData = response.data.data.filter((item) => item.amount > 0)
                //console.log(chartData,'chartData');
                setTopSellerChartData({
                    labels: chartData.map((item) =>
                        item._id.dateString !== undefined ? item._id.dateString + ' ' + dayNames[item._id.dayOfWeek - 1] : item._id
                    ),
                    datasets: [
                        {
                            label: "Total Revenue (£)",
                            data: chartData.map((item) => item.amount.toFixed(2)),
                            backgroundColor: ["#8dc5e4", "#1b81bc"],
                            borderColor: "#E5E5E5",
                            borderWidth: 0,
                            barThickness: 25,
                            maxBarThickness: 40,
                            borderRadius: 4,

                        },
                    ],
                });
            })
            .catch((err) => {
                console.log(err);
            });

    }, [period, reportType]);

    const changeHandler = (e) => {
        setReportType(e.target.value);
    };


    const changePeriodHandler = (e) => {
        setPeriod(e.target.value);
    };
    const options = [];

    for (let i = currentyear; i >= 2024; i--) {
        const year = i;
        options.push(<option value={year + "-" + (year + 1)}>{year + "-" + (year + 1)}</option>);
    }
    let loadingbuffer = isLoading ? 'loading-buffer' : '';
    return (

        <div className={`chart-container chart-revenue ${loadingbuffer}`}>
            <div className="chart-Hdr">

                <h2 style={{ textAlign: "left" }}>
                    {reportType === 'top' ? "Top 25 Revenue Days" : "Bottom 25 Revenue Days"}{"  "}
                </h2>
                <div className="chart-fltr">

                    {<select style={{ }}
                        value={reportType}
                        onChange={(e) => changeHandler(e)}

                    >
                        <option value={"top"}>Top 25 Revenue Days</option>
                        <option value={"low"}>Bottom 25 Revenue Days</option>
                    </select>}
                    {<select style={{ width: '170px' }}
                        value={period}
                        onChange={(e) => changePeriodHandler(e)}

                    >
                        {options}
                    </select>}
                </div>

            </div>
            {topSellerChartData && (
                <Bar
                    data={topSellerChartData}
                    plugins={[noDataPlugin]}
                    options={{
                        scales: {
                            y: {
                                grid: {
                                    color: 'rgba(247, 243, 241, 0.8)',
                                },
                                ticks: {
                                    color: 'rgba(207, 208, 211, 1)',
                                }
                            },
                            x: {
                                barPercentage: 0.5,  // Width of bars relative to available space (0.0 - 1.0)
                                categoryPercentage: 0.8,  // Space between bars in a group (0.0 - 1.0)
                                grid: {
                                    color: 'rgba(247, 243, 241, 0.8)'
                                },
                                ticks: {
                                    color: 'rgba(207, 208, 211, 1)',
                                    autoSkip: false, // Show all dates
                                    maxRotation: 60,  // Keep labels readable
                                    minRotation: 60,
                                    callback: function (value, index, ticks) {
                                        const label = this.getLabelForValue(value); // Get the label for the current tick
                                        if (typeof label === 'string' && label.includes(" ")) {
                                            const [date, dayOfWeek] = label.split(" "); // Split by the comma separator
                                            return [dayOfWeek, date];  // Return the label with a newline
                                        }
                                        return label; // Single-line label for other cases
                                    },
                                }
                            }
                        },

                        responsive: true,
                        layout: {
                            padding: {
                                top: 20,
                                right: 0,
                                bottom: 0,
                                left: 0,
                            }
                        },
                        plugins: {
                            title: {
                                display: false,
                                text: "Top 25 Revenue Days",
                            },
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                titleColor: 'rgba(220, 222, 226, 1)',
                                bodyColor: 'rgba(220, 222, 226, 1)',
                                callbacks: {
                                    title: function (tooltipItems) {
                                        //const dataPoint = topSellerChartData[tooltipItem.dataIndex]; // Get corresponding data point

                                        const originalLabel = tooltipItems[0].label
                                        const parts = originalLabel.split(" ");
                                        if (parts.length >= 2) {
                                            const datePart = parts[0];
                                            const dayPart = parts.slice(1).join(" ");
                                            return `${datePart} (${dayPart})`;
                                        }
                                        return originalLabel;

                                    },
                                },
                            },
                            datalabels: {
                                display: false,
                                font: {
                                    size: 11,
                                    weight: '600',
                                },
                                padding: 5,
                                anchor: 'end',
                                align: "top",
                                formatter: function (value) {
                                    return "£" + value;
                                    //return priceFormat(value);
                                },

                            }
                        },
                    }}
                />
            )}
        </div>
    );
});
