import React, { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Header from '../common/layout/header';
import Footer from '../common/layout/footer';
import Loader from '../common/loader';
import { BaseUrl } from '../common/config';
import Sidebar from '../common/sidebar';
import { http } from '../helper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const EditPromotions = () =>{
   
    ///exit();
    const [loading,setLoading] = useState(true);
    const [inputFieldtitle, setInputFieldtitle] = useState([]); 
    const [existfilename, setExistFilename] = useState([]); 
    const [existImgfilename, setExistImgFilename] = useState([]);
    const [filepath, setFilePath] = useState([]); 
    const [Imgfilepath, setImgFilePath] = useState([]);
    const [fileinput, setFileInput] = useState([]); 
    const [errormsg,setErrormsg] = useState({});
    const [errmessage, setErrMessage] = useState({});
    const [alertPopup,setAlertPopup] = useState(false);
    const [alertMessage,setAlertMessage] = useState([]);
    const [visibleAlert,setVisibleAlert] = useState(false);
    const [visibleAlertPdf,setVisibleAlertPdf] = useState(false);
    const [visibleImageAlert,setVisibleImageAlert] = useState(false);
    const [visibleAlertImage,setVisibleAlertImage] = useState(false);
    const [deleteAlert,setDeleteAlert] = useState(false);
    const [deleteImageAlert,setDeleteImageAlert] = useState(false);
    const [isLoading,setIsLoading]= useState(false);
    const [bannerImage, setBannerImage] = useState(null); // Store the banner image
    const [isFeatured, setIsFeatured] = useState([]);  // For featured checkbox
    const [sortOrder, setSortOrder] = useState(1);  
    const [status, setStatus] = useState([]);   
    const [selectedCategory, setSelectedCategory] = useState('');
    const [bannerDesktop, setBannerDesktop] = useState(null);
    const [bannerMobile, setBannerMobile] = useState(null);
    const [bannerDesktopPreview, setBannerDesktopPreview] = useState(null);
    const [bannerMobilePreview, setBannerMobilePreview] = useState(null);
    const [selectedImageType, setSelectedImageType] = useState(null);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [isDesktopImageDeleted, setIsDesktopImageDeleted] = useState(false);
    const [isMobileImageDeleted, setIsMobileImageDeleted] = useState(false);
    const [existingDesktopImageUrl, setExistingDesktopImageUrl] = useState('');
    const [existingMobileImageUrl, setExistingMobileImageUrl] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
  
    const params = useParams();
    
    const routeChange = () =>{ 
        window.location.href='/promotions'
    }

 
    if(!sessionStorage.getItem("isLoggedIn")){
        window.location.href = '/';
    }
    if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_promotions'))
        {
          window.location.href = '/unauthorized';
        }
   
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await http.get(BaseUrl + `admin/editpromotions/${params.id}`);
          const data = response.data.data[0];
          setInputFieldtitle(data.Title || '');
          setSelectedCategory(data.Category || '');
          const desktopImageUrl = getImageUrl(data.BannerImageDesktop);
          const mobileImageUrl = getImageUrl(data.BannerImageMobile);
          setBannerDesktopPreview(desktopImageUrl);
          setBannerMobilePreview(mobileImageUrl);
          setExistingDesktopImageUrl(extractRelativePath(data.BannerImageDesktop));
          setExistingMobileImageUrl(extractRelativePath(data.BannerImageMobile));
          setIsFeatured(data.InDownloads || false);
          setSortOrder(data.sortOrder || 1);
          setStatus(data.Status || 'active');
          setFromDate(data.FromDate ? moment(data.FromDate, "YYYY-MM-DD").toDate() : '');
          setToDate(data.ToDate ? moment(data.ToDate, "YYYY-MM-DD").toDate() : '');
          
        } catch (error) {
          console.error("Error fetching promotion data:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
      }, [params.id]);
      

      const getImageUrl = (path) => {
        const base = BaseUrl.replace(/\/$/, "");
        if (!path.startsWith('/')) {
          path = '/' + path;
        }
        return base + path;
      };
      const extractRelativePath = (fullUrl) => {
        if (!fullUrl) return "";
        const uploadIndex = fullUrl.indexOf("upload/");
        if (uploadIndex !== -1) {
          let relativePath = fullUrl.substring(uploadIndex);
          return relativePath.startsWith("/") ? relativePath.substring(1) : relativePath;
        };
        return fullUrl;
      };
    
      
      const submitButton = (e) => {
        e.preventDefault();
        if (!formvalidation()) {
          return;
        }
       
        handleUpload();
      };
    
      const handleFromDateChange = (date) => {
        setFromDate(date);
        setErrormsg((prev) => ({ ...prev, fromDateErr: '' }));
        // if (date > toDate) {
        //   setErrormsg({ ...errormsg, toDateErr: "To Date cannot be before From Date!" });
        // } else {
        //   setErrormsg({ ...errormsg, toDateErr: "" });
        // }
      };
    
      const handleToDateChange = (date) => {
        if (date >= fromDate) {
          setToDate(date);
          setErrormsg({ ...errormsg, toDateErr: "" });
        } else {
          setErrormsg({ ...errormsg, toDateErr: "To Date cannot be before From Date!" });
        }
      };
       
    const handleFileUpload = (event, type) => {
        const file = event.target.files[0];
        if (!file) return;
    
        const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
        const fileType = file.type.toLowerCase(); 
        const fileSizeInBytes = file.size;
        const fileSizeInMB = (fileSizeInBytes / 1024 / 1024).toFixed(2); 
        const maxSizeMB = 2;
        let errorMessage = "";
        if (!allowedTypes.includes(fileType)) {
          errorMessage = "Only JPG, JPEG, and PNG files are allowed.";
        } else if (fileSizeInMB > maxSizeMB) {
          errorMessage = "File size must be 2MB or less.";
        }
    
        setErrormsg((prev) => ({ ...prev, [`${type}Err`]: errorMessage }));
        if (errorMessage) {
          event.target.value = "";
          return;
        }
    
    setErrormsg((prev) => ({ ...prev, [`${type}Err`]: "" }));

    if (type === "desktop") {
      setBannerDesktop(file);
      setBannerDesktopPreview(URL.createObjectURL(file));
    } else {
      setBannerMobile(file);
      setBannerMobilePreview(URL.createObjectURL(file));
    }
  };
    
  const eventhandlechange = (e) => {
    const { name, value } = e.target;
    if (name === 'inputFieldtitle') {
        setInputFieldtitle(value);
        setErrormsg((prevErrors) => ({
            ...prevErrors,
            inputFieldtitleerr: value.trim() ? "" : prevErrors.inputFieldtitleerr,
        }));
    }
};

  const handleUpload = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("Title", inputFieldtitle.trim());
      formData.append("Category",(selectedCategory));
      formData.append("InDownloads", isFeatured);
      formData.append("sortOrder", sortOrder);
      formData.append("Status", status);
      formData.append("createdBy", JSON.parse(sessionStorage.getItem("authData")).adminName);
      formData.append("pid", params.id);
      formData.append('fromDate', moment(fromDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
      formData.append('toDate', moment(toDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'));
      //Desktop Image Handling
      if (isDesktopImageDeleted) {
        formData.append("isDesktopImageDeleted", true);
          if (existingDesktopImageUrl) {
            formData.append("existingBannerImageDesktop", existingDesktopImageUrl);
          }
          if (bannerDesktop) {
            formData.append("bannerImageDesktop", bannerDesktop);
          }
      } else {
          formData.append("isDesktopImageDeleted", false);
            if (existingDesktopImageUrl) {
              formData.append("existingBannerImageDesktop", existingDesktopImageUrl);
            }
      }
      //Mobile Image Handling
      if (isMobileImageDeleted) {
        formData.append("isMobileImageDeleted", true);
          if (existingMobileImageUrl) {
            formData.append("existingBannerImageMobile", existingMobileImageUrl);
          }
          if (bannerMobile) {
            formData.append("bannerImageMobile", bannerMobile);
          }
      } else {
          formData.append("isMobileImageDeleted", false);
            if (existingMobileImageUrl) {
              formData.append("existingBannerImageMobile", existingMobileImageUrl);
            }
      }
      const response = await http.put(BaseUrl + 'admin/editpromotions', formData);
      console.log("API Response:", response.data);
      if (response.data.success === true) {
        setAlertMessage('Promotion Banner updated successfully');
        setAlertPopup(true);
      }
    } catch (error) {
      console.error("Error updating promotion:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (loading) return <Loader />;


  const hasExtension = (inputID, exts) => {
    var fileName = document.getElementById(inputID).value;
    return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
}

    const formvalidation = () => {
        let newErrors = {};
        let isValid = true;
    
    if (!inputFieldtitle.trim()) {
      newErrors.inputFieldtitleerr = "This field is required";
      isValid = false;
    }
    if (!selectedCategory) {
      newErrors.categoryErr = "Please select a category";
      isValid = false;
    }
    if (!fromDate) {
      newErrors.fromDateErr = 'Please select a start date';
      isValid = false;
    }
    if (!toDate) {
      newErrors.toDateErr = 'Please select an end date';
      isValid = false;
    } else if (toDate < fromDate) {
      newErrors.toDateErr = "To Date cannot be before From Date!";
      isValid = false;
    }
    if (!bannerDesktop && !bannerDesktopPreview) {
      newErrors.desktopErr = "This field is required";
      isValid = false;
    }
    if (!bannerMobile && !bannerMobilePreview) {
      newErrors.mobileErr = "This field is required";
      isValid = false;
    }
    setErrormsg(newErrors);
    return isValid;
  };

    const handleRadioChange = (event) => {
    const { value } = event.target;
    setSelectedCategory(value);
    setErrormsg((prev) => ({ ...prev, categoryErr: '' }));
    };

    const alertPopupclose = () =>{
        setVisibleAlert(false);
    }
    const alertPopupImgclose =()=>{
        setVisibleImageAlert(false);
    }
    const deletepdf = ()=>{
        setVisibleAlert(true);
    }
    const deleteImage = ()=>{
        setVisibleImageAlert(true);
    }
   
    const deleteFreepdf =() =>{
    setDeleteAlert(true);
    setExistFilename('');
    setVisibleAlert(false);
   
    }

    const deleteBanImage = () => {
      if (selectedImageType === "desktop") {
        setBannerDesktopPreview(null);
        setBannerDesktop(null);
        setIsDesktopImageDeleted(true);
        setExistingDesktopImageUrl(existingDesktopImageUrl); 
      } else if (selectedImageType === "mobile") {
        setBannerMobilePreview(null);
        setBannerMobile(null);
        setIsMobileImageDeleted(true);
        setExistingMobileImageUrl(existingMobileImageUrl);
      }
      setVisibleImageAlert(false); 
  };
  
   const popupClose = (event) => {

        setVisibleAlertPdf(false);
        
}



return (<section className="dashboard-block">
   {alertPopup && ( 
   <div className='custom-alert-pop common_alert_popup' style={{display: "block"}}>
    <div className='custom-alert-container'>
            <div className='custom-alert-wrap'>
                <div className='custom-alert-header'>
                    <h4>Alert</h4>
                </div>
                <div className='custom-alert-body'>
                    <p>{alertMessage}</p>

                    <div className='alert-buttons'>
                        <a onClick={routeChange} className="popup-alert-btn button">OK</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    )}
    
 
        <Sidebar />

 
  <div className="dash-left">
   <Header/>
    <section className="cart-block">
    {!loading &&
    <div className="container">
    <h1 className="checkout-title">Edit Promotion Banner</h1>

    <div class="right-table-outer-wrap">
    {( isLoading ) ? (
                  <div className='loading-buffer'></div>
                ):(  
    
     <form method='post' className="addform editform promo-form freeproduct-fieldset form-container-xs" onSubmit={submitButton}>
    
          <div className="input-field col-1">
          <label>Title</label>
          <input type="text" value={inputFieldtitle} name="inputFieldtitle" onChange={eventhandlechange} className="form-control" placeholder="Title" /> 
          {errormsg.inputFieldtitleerr && (
            <div className="error-msg">{errormsg.inputFieldtitleerr}</div>
            )}
            </div>
       
          <div className="input-field col-1">
          <label>Select the category for this Promotion Banner</label>
          <div className="edit-free-cat-chks">
              {["pizza", "pasta", "burrito"].map((category, index) => {
                  let displayCategory = category === "pasta" 
                      ? "Pasta, Rice & Noodle Sauces" 
                      : category.charAt(0).toUpperCase() + category.slice(1);
                  return (
                      <div className="check-box" key={index}>
                          <input
                              type="radio"
                              id={`freeCat${index}`}
                              name="category"
                              value={category}
                              checked={selectedCategory === category}
                              onChange={handleRadioChange}
                          />
                          <label htmlFor={`freeCat${index}`}>{displayCategory}</label>
                      </div>
                  );
              })}
          </div>
          {errormsg.categoryErr && <div className="error-msg">{errormsg.categoryErr}</div>}
      </div>

      <div className="input-field col-1">
        <label>Dates For the Promotion Banner</label>
      </div>
              <div className="input-field col-1">
                <label className='lbl-blk'>From Date</label>
                <DatePicker
                  selected={fromDate}
                  onChange= {handleFromDateChange}
                  minDate={new Date()}
                  placeholderText="Select From Date"
                  dateFormat='dd-MM-yyyy'
                  onKeyDown={(e) => {
                    e.preventDefault(); 
                }}
                  className="form-control"
                />
                {errormsg.fromDateErr && <div className="error-msg">{errormsg.fromDateErr}</div>}
              </div>

              <div className="input-field col-1">
                <label className='lbl-blk'>To Date</label>
                <DatePicker
                  selected={toDate}
                  onChange= {handleToDateChange}
                  minDate={new Date()}
                  placeholderText="Select To Date"
                  dateFormat='dd-MM-yyyy'
                  onKeyDown={(e) => {
                    e.preventDefault(); 
                }}
                  className="form-control"
                />
                {errormsg.toDateErr && <div className="error-msg">{errormsg.toDateErr}</div>}
              </div>
              <div className="notification-field-set-wrap">
                  <div className="notification-field-set promo-field-set">
                    <label>Banner Image <span className="text-note">(Maximum Upload File Size: 2 MB)</span></label>

                    {/* Desktop Banner */}
                    <div className="input-field col-1">
                      <label>Desktop</label>
                      
                      <div className="flex align-center gap-10">
                        {!bannerDesktopPreview && (
                          <>
                          <input
                            type="file"
                            id="desktop_banner"
                            accept="image/png, image/jpeg, image/jpg"
                            onChange={(e) => handleFileUpload(e, "desktop")}
                          />
                          <span className="text-note">(.jpg, .jpeg, .png files only allowed)*<br /><br /></span>
                         </>
                        )}
                        
                        {bannerDesktopPreview && (
                          <div className="upld-img-file">
                            <div className="upld-img-show">
                              <img src={bannerDesktopPreview} alt="Desktop Preview" />
                              
                              <a className='title-tooltip title-up delete-img' data-title='Delete'
                              onClick={() => {
                                setSelectedImageType("desktop");
                                setDeleteMessage("Are you sure you want to delete Desktop Banner Image?");
                                setVisibleImageAlert(true);
                                }}
                                >
                                  <i className="fa fa-trash" aria-hidden="true"></i></a>
                            </div>
                          </div>
                        )}
                      </div>
                      {errormsg.desktopErr && <div className="error-msg">{errormsg.desktopErr}</div>}
                    </div>

                    {/* Mobile Banner */}
                    <div className="input-field col-1">
                      <label>Mobile</label>
                      
                      <div className="flex align-center">
                        {!bannerMobilePreview && (
                          <>
                          <input
                            type="file"
                            id="mobile_banner"
                            accept="image/png, image/jpeg, image/jpg"
                            onChange={(e) => handleFileUpload(e, "mobile")}
                          />
                          <span className="text-note"> (.jpg, .jpeg, .png files only allowed)*</span>
                          </>
                        )}
                       

                        {bannerMobilePreview && (
                          <div className="upld-img-file">
                            <div className="upld-img-show">
                              <img src={bannerMobilePreview} alt="Mobile Preview" />
                             
                              <a className='title-tooltip title-up delete-img' data-title='Delete'
                              onClick={() => {
                                setSelectedImageType("mobile");
                                setDeleteMessage("Are you sure you want to delete Mobile Banner Image?");
                                setVisibleImageAlert(true);
                              }
                              }>
                                <i className="fa fa-trash" aria-hidden="true"></i></a>
                            </div>
                          </div>
                        )}
                      </div>
                      {errormsg.mobileErr && <div className="error-msg">{errormsg.mobileErr}</div>}
                    </div>
                </div>
            </div>
              {/* Featured Checkbox */}
              <div className="input-field col-1">
                <label>Display for Downloads</label>
                <div className="check-box">
                <input type="checkbox" id='setImg' className='check-box' checked={isFeatured} onChange={(e) => setIsFeatured(e.target.checked)} />
                <label for='setImg'>Yes</label>
                </div>

              </div>

              {/* Sorting Input */}
              <div className="input-field col-4">
                <label className='full-width'>Sort Order</label>
                <input type="number" value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} min="1" placeholder="Order (1 for top priority)" />
              </div>

                {/* Status Dropdown */}
              <div className="input-field col-1">
                <label className='full-width'>Status</label>
                <select className='col-4' value={status} onChange={(e) => setStatus(e.target.value)}>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>

              
        <button type='submit'>Submit</button> &nbsp;&nbsp;&nbsp;
        <button type="button" onClick={routeChange}>Cancel</button>
        </form>)}
        </div>
        </div>
        }

       

       </section>
      <Footer/>
      </div>  
      {loading && <Loader />}
            
      {visibleAlertPdf === true &&
       <div className='custom-alert-pop' style={{display: "block"}}>
        <div className='custom-alert-container'>
            <div className='custom-alert-wrap'>
                <div className='custom-alert-header'>
                    <h4>Alert</h4>
                </div>
                <div className='custom-alert-body'>
                    <p>File size must be less than 5 MB.</p>

                    <div className='alert-buttons'>
                        <a onClick={popupClose} className="popup-alert-btn button">OK</a>
                    </div>
                </div>
            </div>
        </div>
       </div>}

       {visibleImageAlert && 
                <div className='custom-alert-pop paylater-popup'>
                    <div className='custom-alert-container'>
                        <div className='custom-alert-wrap'>
                            <div className='custom-alert-header'>
                                <h4>Alert</h4>
                            </div>
                            <div className='custom-alert-body'>
                            <p>{deleteMessage}</p>

                                <div className='alert-buttons'>

                                    <a onClick={deleteBanImage} className="popup-alert-btn button" >OK</a>
                                    <a onClick={alertPopupImgclose.bind(this)} className="popup-alert-btn button">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}    


      {visibleAlertImage === true &&
       <div className='custom-alert-pop' style={{display: "block"}}>
        <div className='custom-alert-container'>
            <div className='custom-alert-wrap'>
                <div className='custom-alert-header'>
                    <h4>Alert</h4>
                </div>
                <div className='custom-alert-body'>
                    <p>File size must be less than 2 MB.</p>

                    <div className='alert-buttons'>
                        <a onClick={popupClose} className="popup-alert-btn button">OK</a>
                    </div>
                </div>
            </div>
        </div>
       </div>}      
    </section>

   );

}
export default EditPromotions;