// menuPermissions.js
export const menuPermissions = [
    { name: 'Dashboard', value: 'manage_dashboard' },
    { name: 'Add Users', value: 'manage_users' },
    { name: 'Add Roles', value: 'manage_roles' },
    { name: 'Products', value: 'manage_products' },
    { name: 'Free Products Setup', value: 'manage_freeproducts' },
    { name: 'New Products', value: 'manage_newproducts' },
    { name: 'Customers', value: 'manage_customers' },
    { name: 'Orders', value: 'manage_orders' },
    { name: 'Master Entry', value: 'manage_masterentry' },
    { name: 'Settings', value: 'manage_settings' },
    { name: 'Stripe Payment Log', value:'manage_stripelog'},
    { name: 'Food Solutions', value:'manage_foodsolutions'},
    { name: 'Promotions', value:'manage_promotions'},
    // { name: 'View Content', value: 'view_content' },
    // { name: 'Edit Content', value: 'edit_content' },
  ];