import React, { useEffect, useState, memo } from "react";
import { Bar } from "react-chartjs-2";
import { http } from '../helper';
import { BaseUrl } from '../common/config';
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import DatePicker from "react-datepicker";
import { format } from 'date-fns';

Chart.register(CategoryScale);

const priceFormat = (price) => {
    return price.toLocaleString("en-US", {
        style: "currency",
        currency: "GBP",
        minimumFractionDigits: 2,
    });
};

export const HourlyReport = memo(({ }) => {

    const [filter, setFilter] = useState(0);
    const [totalamt, setTotalamt] = useState(0);
    const [totalcnt, setTotalcnt] = useState(0);
    const [typeFilter, setTypeFilter] = useState('Qty Sold');
    const [filtertext, setFilterText] = useState('');

    const [isLoading, setIsLoading] = useState(true);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    // Custom plugin to display "No data found"
    const noDataPlugin = {
        id: "noDataPlugin",
        beforeDraw: (chart) => {
            const { datasets } = chart.data;
            const hasData = datasets.some((dataset) => dataset.data.length > 0);

            if (!hasData) {
                const ctx = chart.ctx;
                const { width, height } = chart;

                ctx.save();
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.font = "20px Arial";
                ctx.fillStyle = "#eb9f9d";
                ctx.fillText("Sorry, no data found for your filtered search.", width / 2, height / 2);
                ctx.restore();
            }
        },
    };

    const [topSellerChartData, setTopSellerChartData] = useState({
        labels: [],
        datasets: [
            {
                label: "Top Selling Products",
                data: [],
                backgroundColor: ["rgba(252,194,84,1)", "#f4ecec"],
                borderColor: "#e6e6e6",
                borderWidth: 2,
            },
        ],
    });



    useEffect(() => {
        if (filter === "custom" && (!fromDate || !toDate)) {
            // Don't make the API call if dates are missing for the "custom" filter
            return;
        }
        let payload = {};
        if (filter === 'custom') {
            payload = {
                filter: filter, datefrom: format(new Date(fromDate), "yyyy-MM-dd"),
                dateto: format(new Date(toDate), "yyyy-MM-dd")
            }
            setShowDatePicker(false);
        }
        else {
            payload = {
                filter: parseInt(filter), datefrom: '',
                dateto: ''
            }
        }
        let URL = BaseUrl + "admin/hourlyreport";
        http.post(URL, payload).then((res) => res.data.data).then((response) => {
            setIsLoading(false);

            response.sort((a, b) => {
                const timeOrder = [
                    "7:00am-8:00am","8:01am-9:00am","9:01am-10:00am","10:01am-11:00am", "11:01am-12:00pm","12:01pm-1:00pm",
                    "1:01pm-2:00pm","2:01pm-3:00pm", "3:01pm-4:00pm","4:01pm-5:00pm","5:01pm-6:00pm", "6:01pm-6:59am"
                ];
                return timeOrder.indexOf(a._id.timeSlot) - timeOrder.indexOf(b._id.timeSlot);
            });

            const chartData = response.filter((item) => item.totalAmount > 0)

            setTotalamt(chartData.reduce((acc, item) => acc + item.totalAmount, 0));
            setTotalcnt(chartData.reduce((acc, item) => acc + item.orderCount, 0));
            setTopSellerChartData({
                labels: chartData.map((item) => item._id.timeSlot),
                datasets: [
                    {
                        label: typeFilter === 'Qty Sold' ? "Order Count (#)" : "Total Revenue (£)",
                        data: chartData.map((item) => typeFilter === 'Qty Sold' ? item.orderCount : item.totalAmount.toFixed(2)),
                        backgroundColor: ["#8dc5e4", "#1b81bc"],
                        borderColor: "#E5E5E5",
                        borderWidth: 0,
                        barThickness: 40,
                        maxBarThickness: 60,
                        borderRadius: 4,

                    },
                ],
            });

        }).catch((err) => {
            console.log(err); 
        })
    }, [filter, fromDate, toDate, typeFilter]);
    const DateChange = (value, type) => {
        if (type === "from") {
            setFromDate(value);

            if (toDate && value && value > toDate) {
                setToDate(null);
            }
        } else if (type === "to") {
            if (!fromDate || value >= fromDate) {
                setToDate(value);
            } else {
                // Handle invalid date selection
                //alert("The 'To' date cannot be earlier than the 'From' date.");
                setToDate(null);
            }
        }
    };

    const changeFilterHandler = (e) => {
        setFilter(e.target.value);
        setFilterText(e.target.options[e.target.selectedIndex].text);
        if (e.target.value === 'custom') {
            setShowDatePicker(true);

        } else {
            setShowDatePicker(false);
            setFromDate(null);
            setToDate(null);
            setFilter(e.target.value);

        }
    };
    const changeTypeFilterHandler = (e) => {
        setTypeFilter(e.target.value);

    };



    const formatDate = (dateString) => {
        const dateObject = new Date(dateString);
        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, "0");
        const day = String(dateObject.getDate()).padStart(2, "0");
        return `${day}/${month}/${year}`;
    }

    let loadingbuffer = isLoading ? 'loading-buffer' : '';

    return (

        <div className={`chart-container chart-category-sales ${loadingbuffer}`}>
            <div className="chart-Hdr">
                <div className="chart-Hdr-txt">
                    <h2 style={{ textAlign: "left" }}>Time Slot Analysis</h2>
                </div>
                <div className="chart-fltr-hldr">

                    <div className="chart-fltr">
                        {<select
                            value={typeFilter}
                            onChange={(e) => changeTypeFilterHandler(e)}

                        >
                            <option value={'Qty Sold'}>Order Count</option>
                            <option value={'total revenue'}>Revenue</option>

                        </select>}
                    </div>

                    <div className="chart-fltr" onMouseEnter={() => {
                        if (filter === "custom" && !showDatePicker) {
                            setShowDatePicker(true);
                        }
                    }}
                        onMouseLeave={() => {
                            if (filter === "custom" && showDatePicker) {
                                setShowDatePicker(false);

                            }
                        }}>
                        {<select
                            value={filter}
                            onChange={(e) => changeFilterHandler(e)}

                        ><option value={0}>All</option>
                            <option value={1}>Past 1 Day</option>
                            <option value={30}>Past 30 Days</option>
                            <option value={90}>Past 90 Days</option>
                            <option value={180}>Past 180 Days</option>
                            <option value={365}>Past 1 Year</option>
                            <option value={'custom'}>Custom Date</option>
                        </select>}
                        {showDatePicker && (
                            <div className="date-picker-container">
                                <label>From:</label>
                                <DatePicker
                                    selected={fromDate}
                                    onChange={(date) => DateChange(date, "from")}
                                    showDisabledMonthNavigation
                                    dateFormat="dd/MM/yyyy"
                                    disablePast={true}
                                    maxDate={new Date()}
                                    placeholderText="Select Date"
                                />
                                <label>To:</label>
                                <DatePicker
                                    selected={toDate}
                                    showDisabledMonthNavigation
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => DateChange(date, "to")}
                                    disablePast={true}
                                    maxDate={new Date()}
                                    placeholderText="Select Date"
                                />
                            </div>
                        )}
                    </div>
                </div>

                <div className="fltr-sub-val">
                    <span className='fltr-val'>{typeFilter === 'Qty Sold' ? "Total Order Count: " + totalcnt : "Total Amount: " + priceFormat(totalamt)}</span>
                    <span className='fltr-val'>({(filtertext != '' && filtertext != 'Custom Date') ? filtertext : (filtertext === 'Custom Date' && fromDate && toDate) ? formatDate(fromDate) + ' - ' + formatDate(toDate) : 'All'})</span>
                </div>
            </div>
            {topSellerChartData && (
                <Bar
                    data={topSellerChartData}
                    plugins={[noDataPlugin]}
                    options={{
                        scales: {
                            y: {
                                grid: {
                                    color: 'rgba(247, 243, 241, 0.8)',
                                },
                                ticks: {
                                    color: 'rgba(207, 208, 211, 1)',
                                }
                            },
                            x: {
                                grid: {
                                    color: 'rgba(247, 243, 241, 0.8)'
                                },
                                ticks: {
                                    color: 'rgba(207, 208, 211, 1)',
                                    maxRotation: 20,  // Keep labels readable
                                    minRotation: 20,
                                    callback: function (value, index, ticks) {
                                        const label = this.getLabelForValue(value); // Get the label for the current tick
                                        if (typeof label === 'string' && label.includes(" ")) {
                                            const [date, dayOfWeek] = label.split(" "); // Split by the comma separator
                                            return [dayOfWeek, date];  // Return the label with a newline
                                        }
                                        return label; // Single-line label for other cases
                                    },
                                }
                            }
                        },
                        layout: {
                            padding: {
                                top: 20,
                                right: 0,
                                bottom: 0,
                                left: 0,
                            }
                        },
                        plugins: {
                            title: {
                                display: false,
                                text: "Revenue Analysis",
                            },
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                titleColor: 'rgba(220, 222, 226, 1)',
                                bodyColor: 'rgba(220, 222, 226, 1)'
                            },
                            datalabels: {
                                display: false,
                                font: {
                                    size: 11,
                                    weight: '600',
                                },
                                padding: 5,
                                anchor: 'end',
                                align: "top",
                                formatter: function (value) {
                                    return typeFilter === 'Qty Sold' ? value : priceFormat(value);
                                    //return priceFormat(value);
                                },

                            }
                        },
                    }}
                />
            )}
        </div>
    );
});