import { useEffect, useState, memo } from "react";
import { Bar } from "react-chartjs-2";
import { http } from "../helper";
import { BaseUrl } from "../common/config";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
Chart.register(CategoryScale);

export const Sectors = memo(({ }) => {
    const [filter, setFilter] = useState(0);
    const [filtertext, setFilterText] = useState("");
    const [typeFilter, setTypeFilter] = useState('totalSpent');
    const [isLoading, setIsLoading] = useState(true);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [totalrecords, SetTotalRecords] = useState(0);

    // Custom plugin to display "No data found"
    const noDataPlugin = {
        id: "noDataPlugin",
        beforeDraw: (chart) => {
            const { datasets } = chart.data;
            const hasData = datasets.some((dataset) => dataset.data.length > 0);

            if (!hasData) {
                const ctx = chart.ctx;
                const { width, height } = chart;

                ctx.save();
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.font = "16px Arial";
                ctx.fillStyle = "#ed5264";
                ctx.fillText(
                    "Sorry, no data found for your filtered search.",
                    width / 2,
                    height / 2
                );
                ctx.restore();
            }
        },
    };

    const [topSellerChartData, setTopSellerChartData] = useState({
        labels: [],
        datasets: [
            {
                label: "Top Selling Products",
                data: [],
                type: "bar",
                backgroundColor: [
                    "#EC932F",
                    "#13C2C2",
                    "#1890FF",
                    "#F5222D",
                    "#2F54EB",
                    "#A0D911",
                    "#722ED1",
                    "#EB2F96",
                    "#52C41A",
                    "#FADB14",
                    "#EC932F",
                    "#9E1068",
                    "#0050B3",
                ],
                borderColor: "#e6e6e6",
                borderWidth: 2,
            },
        ],
    });

    const groupBy = (data) => {
        //console.log(data);
        return data.reduce((acc, d) => {
            if (Object.keys(acc).includes(d.sector)) return acc;
            acc[d.sector] = data.filter((g) => g.sector === d.sector);
            return acc;
        }, {});
    };

    useEffect(() => {
        let URL3 = BaseUrl + "admin/sectorsreport";
        if (filter === "custom" && (!fromDate || !toDate)) {
            // Don't make the API call if dates are missing for the "custom" filter
            return;
        }
        let payload = {};
        if (filter === "custom") {
            payload = {
                filter: filter,
                datefrom: format(new Date(fromDate), "yyyy-MM-dd"),
                dateto: format(new Date(toDate), "yyyy-MM-dd"),
            };
            setShowDatePicker(false);
        } else {
            payload = {
                filter: parseInt(filter),
                datefrom: "",
                dateto: "",
            };
        }

        http
            .post(URL3, payload)
            .then((response) => {
                setIsLoading(false);
                const newarr = groupBy(response.data.data, "sector");
                const chartData = Object.entries(newarr);
                
                SetTotalRecords(
                    chartData.reduce((sectorAcc, sector) => {
                        const sectorSums = sector[1].reduce(
                            (itemAcc, item) => itemAcc + item.sum,
                            0
                        );
                        return sectorAcc + sectorSums;
                    }, 0)
                );
                
                const totalSpent = chartData.map((item) =>
                    item[1]
                        .reduce((totalr, items) => totalr + items.sum, 0)
                        .toFixed(2)
                );
                const averageSpent = chartData.map((item) => {
                    return {
                        amount: item[1]
                            .reduce((totalr, items) => totalr + items.sum, 0)
                            .toFixed(2), orderCount: item[1]
                                .reduce((totalr, items) => totalr + items.orderCount, 0)
                    }
                });

                const averageSpentbycus = chartData.map((item) => {
                    return {
                        amount: item[1]
                            .reduce((totalr, items) => totalr + items.sum, 0)
                            .toFixed(2), customerCount: item[1][0]?.uniqueClientCount || 0
                    }
                });

                setTopSellerChartData({
                    labels: chartData.map((item) => item[0]),
                    datasets: [
                        {
                            label: (typeFilter === 'totalSpent' ? "Total Revenue (£)" : typeFilter === 'averageSpent' ? "Average Spend Per Site  (£)":"Average Spend Per Customer (£)"),
                            type: "bar",
                            data: (typeFilter === 'totalSpent' ? totalSpent : typeFilter === 'averageSpent' ? averageSpent.map((item) => (item.amount / item.orderCount).toFixed(2)): averageSpentbycus.map((item) => (item.amount / item.customerCount).toFixed(2))),
                            backgroundColor: [
                                "#AFDC8F",
                                "#F8AE54",
                                "#f7b1ae",
                                "#92C5F9",
                                "#B6A6E9",
                                "#42d4f4",
                                "#a9a9a9",
                            ],
                            borderColor: "#f6f6f6",
                            borderWidth: 0,
                            barThickness: 40,
                            maxBarThickness: 60,
                            borderRadius: 4,
                        },
                    ],
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }, [filter, fromDate, toDate, typeFilter]);

    const DateChange = (value, type) => {
        if (type === "from") {
            setFromDate(value);

            if (toDate && value && value > toDate) {
                setToDate(null);
            }
        } else if (type === "to") {
            if (!fromDate || value >= fromDate) {
                setToDate(value);
            } else {
                // Handle invalid date selection
                //alert("The 'To' date cannot be earlier than the 'From' date.");
                setToDate(null);
            }
        }
    };

    const priceFormat = (price) => {
        return price.toLocaleString("en-US", {
            style: "currency",
            currency: "GBP",
            minimumFractionDigits: 2,
        });
    };

    const changeFilterHandler = (e) => {
        setFilter(e.target.value);
        setFilterText(e.target.options[e.target.selectedIndex].text);
        if (e.target.value === "custom") {
            setShowDatePicker(true);
        } else {
            setShowDatePicker(false);
            setFromDate(null);
            setToDate(null);
            setFilter(e.target.value);
        }
    };

    const formatDate = (dateString) => {
        const dateObject = new Date(dateString);
        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, "0");
        const day = String(dateObject.getDate()).padStart(2, "0");
        return `${day}/${month}/${year}`;
    };

    const changeTypeFilterHandler = (e) => {
        setTypeFilter(e.target.value);

    };


    let loadingbuffer = isLoading ? "loading-buffer" : "";

    return (
        <div className={`chart-container chart-revenue ${loadingbuffer}`}>
            <div className="chart-Hdr">
                <h2 style={{ textAlign: "left" }}>Sector Revenue Analysis{"  "}</h2>
                <div className="chart-fltr-Hldr">
                <div className="chart-fltr">
                        {
                            <select
                                value={typeFilter}
                                onChange={(e) => changeTypeFilterHandler(e)}
                            >
                                <option value={"totalSpent"}>Total Revenue</option>
                                <option value={"averageSpent"}>Average Spend Per Site</option>
                                <option value={"averageSpentbyCus"}>Average Spend Per Customer</option>
                            </select>
                        }
                </div>
                <div
                    className="chart-fltr"
                    onMouseEnter={() => {
                        if (filter === "custom" && !showDatePicker) {
                            setShowDatePicker(true);
                        }
                    }}
                    onMouseLeave={() => {
                        if (filter === "custom" && showDatePicker) {
                            setShowDatePicker(false);
                        }
                    }}
                >
                    {
                        <select value={filter} onChange={(e) => changeFilterHandler(e)}>
                            <option value={0}>All</option>
                            <option value={30}>Past 30 Days</option>
                            <option value={90}>Past 90 Days</option>
                            <option value={180}>Past 180 Days</option>
                            <option value={365}>Past 1 Year</option>
                            <option value={"custom"}>Custom Date</option>
                        </select>
                    }
                    {showDatePicker && (
                        <div className="date-picker-container">
                            <label>From:</label>
                            <DatePicker
                                selected={fromDate}
                                onChange={(date) => DateChange(date, "from")}
                                showDisabledMonthNavigation
                                dateFormat="dd/MM/yyyy"
                                disablePast={true}
                                maxDate={new Date()}
                                placeholderText="Select From Date"
                            />
                            <label>To:</label>
                            <DatePicker
                                selected={toDate}
                                showDisabledMonthNavigation
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => DateChange(date, "to")}
                                disablePast={true}
                                maxDate={new Date()}
                                placeholderText="Select To Date"
                            />
                        </div>
                    )}
                </div>
                </div>
                <div className="fltr-sub-val">
                    {topSellerChartData && (
                        <span className="fltr-val">
                            Total Amount: <strong>{priceFormat(totalrecords)}</strong> (
                            {filtertext != "" && filtertext != "Custom Date"
                                ? filtertext
                                : filtertext === "Custom Date" && fromDate && toDate
                                    ? formatDate(fromDate) + " - " + formatDate(toDate)
                                    : "All"}
                            )
                        </span>
                    )}
                </div>
            </div>
            {topSellerChartData && (
                <Bar
                    data={topSellerChartData}
                    plugins={[noDataPlugin]}
                    // options={options}
                    options={{
                        indexAxis: "y",
                        scales: {
                            y: {
                                grid: {
                                    color: "rgba(247, 243, 241, 0.8)",
                                },
                                ticks: {
                                    color: "rgba(207, 208, 211, 1)",
                                },
                            },
                            x: {
                                grid: {
                                    color: "rgba(247, 243, 241, 0.8)",
                                },
                                ticks: {
                                    color: "rgba(207, 208, 211, 1)",
                                },
                            },
                        },
                        plugins: {
                            title: {
                                display: false,
                                text: "Sector Revenue Chart",
                            },
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                titleColor: "rgba(220, 222, 226, 1)",
                                bodyColor: "rgba(220, 222, 226, 1)",
                            },
                            datalabels: {
                                display: false,
                                font: {
                                    size: 11,
                                    weight: "bold",
                                },
                                anchor: "end",
                                align: "end",
                                formatter: function (value) {
                                    return "£" + value;
                                },
                            },
                        },
                    }}
                />
            )}
        </div>
    );
});
