import React, { useEffect, useState } from 'react';
import DataTable, { SortOrder } from 'react-data-table-component';
import { http } from '../helper';
import { BaseUrl } from '../common/config';
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
export const TopCustomers = ({ }) => {

    const priceFormat = (price) => {
        return price.toLocaleString("en-US", {
            style: "currency",
            currency: "GBP",
            minimumFractionDigits: 2,
        });
    };

    const columns = [
        {
            name: "Account Code",
            selector: row => row.accountCode,
            sortable: false,
        },
        {
            name: "Account Name",
            selector: row => row.accountName,
            sortable: false,
        },

        {
            name: "Date Onboarded",
            selector: row => row.accountCreatedAt,
            sortable: true,
            sortFunction: (a, b) => {
                const dateA = a.accountCreatedAt ? new Date(a.accountCreatedAt).getTime() : -Infinity;
                const dateB = b.accountCreatedAt ? new Date(b.accountCreatedAt).getTime() : -Infinity;
                return dateA - dateB;
            },
            cell: row => {
                if (!row.accountCreatedAt) return '';
                const originalDate = new Date(row.accountCreatedAt);
                const comparisonDate = new Date('2024-09-02');
                const displayDate = originalDate < comparisonDate ? comparisonDate : originalDate;
                return formatDate(displayDate);
            }
        },        
        {
            name: "No. of Orders",
            selector: row => row.orderCount,
            sortable: true,
        },
        {
            name: "Ordered Amount",
            selector: row => priceFormat(row.sum),
            sortable: true,
            sortFunction: (a, b) => {
                const totalA = parseFloat(a.sum) || 0;
                const totalB = parseFloat(b.sum) || 0;
                return totalA - totalB;
            },
        },
        {
            name: "Avg. Spent",
            selector: row => priceFormat(row.sum / row.orderCount),
            sortable: true,
            sortFunction: (a, b) => {
                const avgA = parseFloat((a.sum/ a.orderCount));
                const avgB = parseFloat((b.sum / b.orderCount));
                return avgA - avgB;
            },
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '25px',
            },
        },
        headCells: {
            style: {
                padding: '8px', // override the cell padding for head cells

            },
        },
        cells: {
            style: {
                padding: '8px', // override the cell padding for data cells

            },
        },
    };

    const paginationComponentOptions = {
        rowsPerPageText: "",
        rangeSeparatorText: "",
        selectAllRowsItem: true,
        selectAllRowsItemText: "",
        noRowsPerPage: "",
    };

    const [filter, setFilter] = useState(0);
    const [filtertext, setFilterText] = useState('');
    const [topOrderedCustomers, setTopOrderedCustomers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
   //const [FilterType, setFilterType] = useState("revenue");

    useEffect(() => {
        if (filter === "custom" && (!fromDate || !toDate)) {
            // Don't make the API call if dates are missing for the "custom" filter
            return;
        }
        let payload = {};
        if (filter === 'custom') {
            payload = {
                filter: filter, datefrom: format(new Date(fromDate), "yyyy-MM-dd"),
                dateto: format(new Date(toDate), "yyyy-MM-dd"),
                //FilterType: FilterType
            }
            setShowDatePicker(false);
        }
        else {
            payload = {
                filter: parseInt(filter), datefrom: '',
                dateto: '',
               // FilterType: FilterType
            }
        }
        let URL2 = BaseUrl + "admin/topcustomers";
        http.post(URL2, payload).then((response) => {
            setIsLoading(false);
            setTopOrderedCustomers(response.data.data.filter((item) => item.sum > 0));


        }).catch((err) => {
            console.log(err);
        })

    }, [filter, fromDate, toDate]);

    const changeFilterHandler = (e) => {

        setFilter(e.target.value);
        setFilterText(e.target.options[e.target.selectedIndex].text);
        if (e.target.value === 'custom') {
            setShowDatePicker(true);

        } else {
            setShowDatePicker(false);
            setFromDate(null);
            setToDate(null);
            setFilter(e.target.value);

        }
    };

    // const changeFilterTypeHandler = (e) => {
    //     setFilterType(e.target.value);
    // };
    
    const formatDate = (dateString) => {
        const dateObject = new Date(dateString);
        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, "0");
        const day = String(dateObject.getDate()).padStart(2, "0");
        return `${day}/${month}/${year}`;
    }

    const DateChange = (value, type) => {
        if (type === "from") {
            setFromDate(value);

            if (toDate && value && value > toDate) {
                setToDate(null);
            }
        } else if (type === "to") {
            if (!fromDate || value >= fromDate) {
                setToDate(value);
            } else {
                // Handle invalid date selection
                //alert("The 'To' date cannot be earlier than the 'From' date.");
                setToDate(null);
            }
        }
    };

    // Calculate dynamic pagination options
    const calculatePaginationOptions = (totalRecords, interval) => {
        const options = [];
        for (let i = interval; i <= totalRecords; i += interval) {
            options.push(i);
        }
        return options;
    };

    const totalRecords = topOrderedCustomers.length; // Get the total records count
    const paginationOptions = calculatePaginationOptions(totalRecords, 10); // Generate options with interval of 10

    let loadingbuffer = isLoading ? 'loading-buffer' : '';
    return (
        <div className={`chart-container chart-high-customers ${loadingbuffer}`} style={{ verticalAlign: "top" }}>
            <div className="chart-Hdr">
                <div className="chart-Hdr-txt">
                    <h2>High-Value Customers </h2>
                </div>
                {/* New Sort Filter Dropdown */}
                {/* <div className="chart-fltr">
                    <select value={FilterType} onChange={(e) => changeFilterTypeHandler(e)}>
                        <option value="revenue">Revenue</option>
                        <option value="dateOnboarded">Date Onboarded</option>
                    </select>
                </div> */}
            
                <div className="chart-fltr" onMouseEnter={() => {
                    if (filter === "custom" && !showDatePicker) {
                        setShowDatePicker(true);
                    }
                }}
                    onMouseLeave={() => {
                        if (filter === "custom" && showDatePicker) {
                            setShowDatePicker(false);

                        }
                    }}>
                    {<select
                        value={filter}
                        onChange={(e) => changeFilterHandler(e)}
                    ><option value={0}>All</option>
                        <option value={30}>Past 30 Days</option>
                        <option value={90}>Past 90 Days</option>
                        <option value={180}>Past 180 Days</option>
                        <option value={365}>Past 1 Year</option>
                        <option value={'custom'}>Custom Date</option>
                    </select>}
                    {showDatePicker && (
                        <div className="date-picker-container">
                            <label>From:</label>
                            <DatePicker
                                selected={fromDate}
                                onChange={(date) => DateChange(date, "from")}
                                showDisabledMonthNavigation
                                dateFormat="dd/MM/yyyy"
                                disablePast={true}
                                maxDate={new Date()}
                                placeholderText="Select Date"
                            />
                            <label>To:</label>
                            <DatePicker
                                selected={toDate}
                                showDisabledMonthNavigation
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => DateChange(date, "to")}
                                disablePast={true}
                                maxDate={new Date()}
                                placeholderText="Select Date"
                            />
                        </div>
                    )}
                </div>
                <div className="fltr-sub-val">
                    {topOrderedCustomers.length > 0 && (<span className='fltr-val'>Total Records: <strong>{topOrderedCustomers.length}</strong> ({(filtertext != '' && filtertext != 'Custom Date') ? filtertext : (filtertext === 'Custom Date' && fromDate && toDate) ? formatDate(fromDate) + ' - ' + formatDate(toDate) : 'All'})</span>)}
                </div>
            </div>
            <DataTable
                columns={columns}
                data={topOrderedCustomers}
                customStyles={customStyles}
                noDataComponent={<div className='no-records-found'>There are no records to display</div>}
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 40, 60, 80, 100]}
                //paginationRowsPerPageOptions={paginationOptions}
                paginationComponentOptions={paginationComponentOptions}
            // paginationComponentOptions={{
            //     rowsPerPageText: '', // Hides "Rows per page"
            // }}
            />
        </div>
    );
};