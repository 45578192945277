import { useEffect, useState, memo } from "react";
import { Line } from "react-chartjs-2";
import { http } from "../helper";
import { BaseUrl } from "../common/config";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const Weeklyreport = memo(({ }) => {
    var currentTime = new Date();
    var currentyear = currentTime.getFullYear();

    const [period, setPeriod] = useState("2024-2025");
    const [isLoading, setIsLoading] = useState(true);

    // Custom plugin to display "No data found"
    const noDataPlugin = {
        id: "noDataPlugin",
        beforeDraw: (chart) => {
            const { datasets } = chart.data;
            const hasData = datasets.some((dataset) => dataset.data.length > 0);

            if (!hasData) {
                const ctx = chart.ctx;
                const { width, height } = chart;

                ctx.save();
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.font = "16px Arial";
                ctx.fillStyle = "#eb9f9d";
                ctx.fillText(
                    "Sorry, no data found for your filtered search.",
                    width / 2,
                    height / 2
                );
                ctx.restore();
            }
        },
    };

    const [topSellerChartData, setTopSellerChartData] = useState({
        labels: [],
        datasets: [
            {
                label: "Weekly report",
                data: [],
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
        ],
    });

    const sumObj = (objArr) => {
        var newObj = {};

        objArr.forEach(function (obj) {
            if (obj._id in newObj) {
                newObj[obj._id] += obj.amount;
            } else {
                newObj[obj._id] = obj.amount;
            }
        });

        var arr = [];

        for (var prop in newObj) {
            arr.push({ _id: Number(prop), amount: newObj[prop] });
        }

        return arr;
    };

    useEffect(() => {
        let URL3 = BaseUrl + "admin/weeklyreport";
        http
            .post(URL3, { period: period })
            .then((response) => {
                setIsLoading(false);

                let chartData = sumObj(response?.data?.data);
                chartData = [...chartData.slice(0, -1)];
                setTopSellerChartData({
                    labels: chartData.map((item) => item._id),
                    datasets: [
                        {
                            label: "Revenue (£)",
                            data: chartData.map((item) => item.amount),
                            borderColor: "rgb(255, 99, 132)",
                            backgroundColor: "rgba(255, 99, 132, 0.5)",
                        },
                    ],
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }, [period]);

    const changePeriodHandler = (e) => {
        setPeriod(e.target.value);
    };
    const options = [];

    for (let i = currentyear; i >= 2023; i--) {
        const year = i;
        options.push(
            <option value={year + "-" + (year + 1)}>{year + "-" + (year + 1)}</option>
        );
    }
    let loadingbuffer = isLoading ? "loading-buffer" : "";
    return (
        <div className={`chart-container chart-week-revenue ${loadingbuffer}`}>
            <div className="chart-Hdr">
                <h2 style={{ textAlign: "left" }}>Weekly Revenue Analysis{"  "}</h2>
                <div className="chart-fltr">
                    <select
                        style={{ width: "170px" }}
                        value={period}
                        onChange={(e) => changePeriodHandler(e)}
                    >
                        {options}
                    </select>
                </div>
            </div>
            {topSellerChartData && (
                <Line
                    data={topSellerChartData}
                    plugins={[noDataPlugin]}
                    options={{
                        responsive: true,
                        plugins: {
                            legend: {
                                display: false,
                            },
                            title: {
                                display: false,
                                text: "Weekly revenue analysis",
                            },
                            datalabels: {
                                display: false,
                                font: {
                                    weight: "bold",
                                },
                                anchor: "end",
                                align: "top",
                                formatter: function (value) {
                                    return "£" + value;
                                },
                            },
                            tooltip: {
                                callbacks: {
                                    title: (tooltipItems) => {
                                        const index = tooltipItems[0].label;
                                        return `Week ${index}`;
                                    },
                                },
                            },
                        },
                    }}
                />
            )}
        </div>
    );
});
