import React, { useEffect, useState } from 'react';
import DataTable, { SortOrder } from 'react-data-table-component';
import { http } from '../helper';
import { BaseUrl } from '../common/config';
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import Popup from "../common/popup";
export const PriceBandReport = ({ }) => {
    
    const priceFormat = (price) => {
        return price.toLocaleString("en-US", {
            style: "currency",
            currency: "GBP",
            minimumFractionDigits: 2,
        });
    };

    const viewCustomers = (priceBand) => {

        setLoading(true);

        let payload = { priceBand: priceBand };
        let URL2 = BaseUrl + "admin/getaccountsbasedonpricelist";
        http.post(URL2, payload).then((response) => {
            setLoading(false);
            const updatedAccounts = response.data.data.map(account => {
                const existsInOrders = ordersbyAccountCode.some(order => order._id === account.accountCode);
                return { ...account, ordered: existsInOrders };
            });
            setAccountPriceBand(priceBand);
            setPriceBandAccounts(updatedAccounts);
            setPopupView(true);

        }).catch((err) => {
            setLoading(false);
            console.log(err);
        })
    }

    const viewOrderedCustomers = (priceBand) => {

        setLoading(true);

        let payload = { priceBand: priceBand };
        let URL2 = BaseUrl + "admin/getaccountsbasedonpricelist";
        http.post(URL2, payload).then((response) => {
            setLoading(false);
            const updatedAccounts = response.data.data.map(account => {
                const existsInOrders = ordersbyAccountCode.some(order => order._id === account.accountCode);
                return { ...account, ordered: existsInOrders };
            }).filter(account => account.ordered);
            setAccountPriceBand(priceBand);
            setOrderedAccounts(updatedAccounts);
            setorderedPopupView(true);

        }).catch((err) => {
            setLoading(false);
            console.log(err);
        })
    }

    const columns = [
        {
            name: "Price Band",
            selector: row => row._id,
            sortable: false,
        },
        {
            name: "No. of Orders",
            selector: row => row.orderCount,
            sortable: true,
        },

        {
            name: "Ordered Amount",
            selector: row => priceFormat(row.sum),
            sortable: true,
            sortFunction: (a, b) => {
                const totalA = parseFloat(a.sum) || 0;
                const totalB = parseFloat(b.sum) || 0;
                return totalA - totalB;
            },
        },
        {
            name: "Avg. Spent",
            selector: row => priceFormat(row.sum / row.orderCount),
            sortable: true,
            sortFunction: (a, b) => {
                const avgA = parseFloat((a.sum / a.orderCount));
                const avgB = parseFloat((b.sum / b.orderCount));
                return avgA - avgB;
            },
        },
        {
            name: "Ordered Customers",
            
            selector: row => (
                <a
                    className="title-tooltip title-up button btn-sl"
                    data-title="View Customers"
                    style={{ color: "white" }}
                    onClick={() => viewOrderedCustomers(row._id)}
                >
                    {row.accountCount}
                </a>
            ), 
            sortable: false,
        },
        {
            name: "No. of Customers",
            selector: row => (
                <a
                    className="title-tooltip title-up button"
                    data-title="View Customers"
                    style={{ color: "white" }}

                    onClick={() => viewCustomers(row._id)}
                >
                    {getCountByPriceBand(row._id) + ' Customers'}
                </a>
            ),
        },
        
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '25px',
            },
        },
        headCells: {
            style: {
                padding: '8px', // override the cell padding for head cells

            },
        },
        cells: {
            style: {
                padding: '8px', // override the cell padding for data cells

            },
        },
    };

    const paginationComponentOptions = {
        rowsPerPageText: "",
        rangeSeparatorText: "",
        selectAllRowsItem: true,
        selectAllRowsItemText: "",
        noRowsPerPage: "",
    };

    const [filter, setFilter] = useState(0);
    const [filtertext, setFilterText] = useState('');
    const [topOrderedCustomers, setTopOrderedCustomers] = useState([]);
    const [priceBandAccounts, setPriceBandAccounts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [accountCounts, setAccountCounts] = useState([]);
    const [popupView, setPopupView] = useState(false);
    const [orderedpopupView, setorderedPopupView] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ordersbyAccountCode, setOrdersbyAccountCode] = useState([]);
    const [accountPriceband,setAccountPriceBand] = useState([]);
    const [orderedAccounts,setOrderedAccounts]= useState([]);

    const getCountByPriceBand = (priceBand) => {
        const account = accountCounts.find(account => account._id === priceBand);
        return account ? account.count : 0;
    };


    useEffect(() => {
        let URL2 = BaseUrl + "admin/getaccountcount";
        http.post(URL2).then((response) => {
            setAccountCounts(response.data.data);

        }).catch((err) => {
            console.log(err);
        })
    }, []);

    useEffect(() => {
        if (filter === "custom" && (!fromDate || !toDate)) {
            // Don't make the API call if dates are missing for the "custom" filter
            return;
        }
        let payload = {};
        if (filter === 'custom') {
            payload = {
                filter: filter, datefrom: format(new Date(fromDate), "yyyy-MM-dd"),
                dateto: format(new Date(toDate), "yyyy-MM-dd")
            }
            setShowDatePicker(false);
        }
        else {
            payload = {
                filter: parseInt(filter), datefrom: '',
                dateto: ''
            }
        }

        let URL3 = BaseUrl + "admin/getordersgroupedbyaccountcode";
        http.post(URL3, payload).then((response) => {

            setOrdersbyAccountCode(response.data.data);

        }).catch((err) => {

            console.log(err);
        })

        let URL2 = BaseUrl + "admin/pricebandreport";
        http.post(URL2, payload).then((response) => {
            setIsLoading(false);
            setTopOrderedCustomers(response.data.data.filter((item) => item.sum > 0));


        }).catch((err) => {
            console.log(err);
        })

    }, [filter, fromDate, toDate]);

    const changeFilterHandler = (e) => {

        setFilter(e.target.value);
        setFilterText(e.target.options[e.target.selectedIndex].text);
        if (e.target.value === 'custom') {
            setShowDatePicker(true);

        } else {
            setShowDatePicker(false);
            setFromDate(null);
            setToDate(null);
            setFilter(e.target.value);

        }
    };
    const formatDate = (dateString) => {
        const dateObject = new Date(dateString);
        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, "0");
        const day = String(dateObject.getDate()).padStart(2, "0");
        return `${day}/${month}/${year}`;
    }

    const DateChange = (value, type) => {
        if (type === "from") {
            setFromDate(value);

            if (toDate && value && value > toDate) {
                setToDate(null);
            }
        } else if (type === "to") {
            if (!fromDate || value >= fromDate) {
                setToDate(value);
            } else {
                // Handle invalid date selection
                //alert("The 'To' date cannot be earlier than the 'From' date.");
                setToDate(null);
            }
        }
    };

    // Calculate dynamic pagination options
    const calculatePaginationOptions = (totalRecords, interval) => {
        const options = [];
        for (let i = interval; i <= totalRecords; i += interval) {
            options.push(i);
        }
        return options;
    };

    const totalRecords = topOrderedCustomers.length; // Get the total records count
    const paginationOptions = calculatePaginationOptions(totalRecords, 10); // Generate options with interval of 10
    //console.log('priceBandAccounts',priceBandAccounts)
    let loadingbuffer = isLoading ? 'loading-buffer' : '';
    return (
        <div className={`chart-container chart-high-priceband ${loadingbuffer}`} style={{ verticalAlign: "top" }}>
            <div className="chart-Hdr">
                <div className="chart-Hdr-txt">
                    <h2>High-Value Price Bands </h2>
                </div>
                <div className="chart-fltr" onMouseEnter={() => {
                    if (filter === "custom" && !showDatePicker) {
                        setShowDatePicker(true);
                    }
                }}
                    onMouseLeave={() => {
                        if (filter === "custom" && showDatePicker) {
                            setShowDatePicker(false);

                        }
                    }}>
                    {<select
                        value={filter}
                        onChange={(e) => changeFilterHandler(e)}
                    ><option value={0}>All</option>
                        <option value={30}>Past 30 Days</option>
                        <option value={90}>Past 90 Days</option>
                        <option value={180}>Past 180 Days</option>
                        <option value={365}>Past 1 Year</option>
                        <option value={'custom'}>Custom Date</option>
                    </select>}
                    {showDatePicker && (
                        <div className="date-picker-container">
                            <label>From:</label>
                            <DatePicker
                                selected={fromDate}
                                onChange={(date) => DateChange(date, "from")}
                                showDisabledMonthNavigation
                                dateFormat="dd/MM/yyyy"
                                disablePast={true}
                                maxDate={new Date()}
                                placeholderText="Select Date"
                            />
                            <label>To:</label>
                            <DatePicker
                                selected={toDate}
                                showDisabledMonthNavigation
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => DateChange(date, "to")}
                                disablePast={true}
                                maxDate={new Date()}
                                placeholderText="Select Date"
                            />
                        </div>
                    )}
                </div>
                <div className="fltr-sub-val">
                    {topOrderedCustomers.length > 0 && (<span className='fltr-val'>Total Records: <strong>{topOrderedCustomers.length}</strong> ({(filtertext != '' && filtertext != 'Custom Date') ? filtertext : (filtertext === 'Custom Date' && fromDate && toDate) ? formatDate(fromDate) + ' - ' + formatDate(toDate) : 'All'})</span>)}
                </div>
            </div>
            <DataTable
                columns={columns}
                data={topOrderedCustomers}
                customStyles={customStyles}
                noDataComponent={<div className='no-records-found'>There are no records to display</div>}
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 40, 60, 80, 100]}
                //paginationRowsPerPageOptions={paginationOptions}
                paginationComponentOptions={paginationComponentOptions}
            // paginationComponentOptions={{
            //     rowsPerPageText: '', // Hides "Rows per page"
            // }}
            />

            {popupView && <Popup
                extClass="order-pop priceBand-cart-details-pop"
                headMoreTxt={
                    <>
                        <div className="order-top">


                        </div> </>
                }
                closebtn={
                    <>
                        <div className="pop-header-top order-top">
                            <div class="head"><h4>{accountPriceband} - Customers</h4></div>
                            <span onClick={() => setPopupView(false)} className="close"></span>
                        </div>
                    </>
                }
                bodyMoreTxt={
                    <>
                        <div className="modal-table">
                            {/* <h4 className="item-title">Customers</h4> */}
                            {loading && (
                                <div className="loading-overlay">
                                    <div className="loading-message">Loading...</div>
                                </div>
                            )}

                            {!loading && <div className="checkout-table">
                                <div className="table_overflow">
                                    <table>
                                        <thead>
                                            <tr>

                                                <td align="left">Account Code</td>
                                                <td align="left">Account Name</td>
                                                <td align="center">Ordered</td>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {priceBandAccounts.length > 0 && priceBandAccounts.sort((a, b) => b.ordered - a.ordered).map((item, index) => {
                                                return (
                                                    <tr key={index} >
                                                        <td>
                                                            {item.accountCode}
                                                        </td>
                                                        <td align="left">
                                                            {item.accountName}
                                                        </td>
                                                        <td align="center">
                                                            {/* {item.ordered ? 'Yes' : 'No'} */}
                                                            {item.ordered ? <span className="price-ord-yes"><i class="fa fa-check" aria-hidden="true"></i></span> : <span className="price-ord-no"><i class="fa fa-times" aria-hidden="true"></i></span>}
                                                        </td>
                                                    </tr>

                                                );
                                            })}
                                            {/* Added Special offer and product number like frontend by k2b Sangeetha   */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                        </div>
                    </>
                }
            />}

{orderedpopupView && <Popup
                extClass="order-pop priceBand-cart-details-pop pop-container-sm"
                headMoreTxt={
                    <>
                        <div className="order-top">


                        </div> </>
                }
                closebtn={
                    <>
                        <div className="pop-header-top order-top">
                            <div class="head"><h4>{accountPriceband} - Customers</h4></div>
                            <span onClick={() => setorderedPopupView(false)} className="close"></span>
                        </div>
                    </>
                }
                bodyMoreTxt={
                    <>
                        <div className="modal-table">
                            {/* <h4 className="item-title">Customers</h4> */}
                            {loading && (
                                <div className="loading-overlay">
                                    <div className="loading-message">Loading...</div>
                                </div>
                            )}

                            {!loading && <div className="checkout-table">
                                <div className="table_overflow">
                                    <table>
                                        <thead>
                                            <tr>

                                                <td align="left">Account Code</td>
                                                <td align="left">Account Name</td>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {orderedAccounts.length > 0 && orderedAccounts.map((item, index) => {
                                                return (
                                                    <tr key={index} >
                                                        <td>
                                                            {item.accountCode}
                                                        </td>
                                                        <td align="left">
                                                            {item.accountName}
                                                        </td>
                                                        
                                                    </tr>

                                                );
                                            })}
                                            {/* Added Special offer and product number like frontend by k2b Sangeetha   */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                        </div>
                    </>
                }
            />}

        </div>
    );
};