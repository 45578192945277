import { useEffect, useState, memo } from "react";
import { Line } from "react-chartjs-2";
import { http } from "../helper";
import { BaseUrl } from "../common/config";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const SectorMonthlyReport = memo(({ }) => {
    var currentTime = new Date();
    var currentyear = currentTime.getFullYear();

    const [period, setPeriod] = useState("2024-2025");
    const [isLoading, setIsLoading] = useState(true);

    // Custom plugin to display "No data found"
    const noDataPlugin = {
        id: "noDataPlugin",
        beforeDraw: (chart) => {
            const { datasets } = chart.data;
            const hasData = datasets.some((dataset) => dataset.data.length > 0);

            if (!hasData) {
                const ctx = chart.ctx;
                const { width, height } = chart;

                ctx.save();
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.font = "16px Arial";
                ctx.fillStyle = "#eb9f9d";
                ctx.fillText(
                    "Sorry, no data found for your filtered search.",
                    width / 2,
                    height / 2
                );
                ctx.restore();
            }
        },
    };

    const [topSellerChartData, setTopSellerChartData] = useState({
        labels: [],
        datasets: [
            {
                label: "sector report",
                data: [],
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
        ],
    });



    const groupBySector = (data) => {
        return data.reduce((acc, item) => {
            const sector = item._id.sector;
            if (!acc[sector]) {
                acc[sector] = [];
            }
            acc[sector].push(item);
            return acc;
        }, {});
    };

    const getRandomColor = () => {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        return `rgb(${r}, ${g}, ${b})`;
    };

    const getDataForMonthYear = (result, uniqueMonthYears) => {
        return result.map((item) => {

            return {
                label: `${item?.sector}`,
                data: uniqueMonthYears.map((monthYear) => {
                    const foundItem = item?.data.find((itemValue) => {
                        const monthYearVal = `${itemValue._id.month}/${itemValue._id.year}`;
                        return monthYear === monthYearVal;
                    });
                    return foundItem ? (foundItem.amount / foundItem.orderCount).toFixed(2) : 0;
                }),
                borderColor: getRandomColor(),
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            };
        });
    };

    const getUniqueMonthYear = (data) => {
        const uniqueMonthYearSet = new Set();
        data.forEach(item => {
            const monthYear = `${item._id.month}/${item._id.year}`;
            uniqueMonthYearSet.add(monthYear);
        });
        return Array.from(uniqueMonthYearSet);
    };

    useEffect(() => {
        let URL3 = BaseUrl + "admin/monthlysectorreport";
        http
            .post(URL3, { period: period })
            .then((response) => {
                setIsLoading(false);

                let chartData = groupBySector(response?.data?.data);
                const uniqueMonthYears = getUniqueMonthYear(response?.data?.data);
                const result = Object.keys(chartData).map(sector => ({
                    sector: sector,
                    data: chartData[sector]
                }));
                const datasets = getDataForMonthYear(result, uniqueMonthYears);

                setTopSellerChartData({
                    labels: uniqueMonthYears,
                    datasets: [...datasets],
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }, [period]);

    const changePeriodHandler = (e) => {
        setPeriod(e.target.value);
    };
    const options = [];

    for (let i = currentyear; i >= 2023; i--) {
        const year = i;
        options.push(
            <option value={year + "-" + (year + 1)}>{year + "-" + (year + 1)}</option>
        );
    }
    let loadingbuffer = isLoading ? "loading-buffer" : "";
    return (
        <div className={`chart-container chart-week-revenue ${loadingbuffer}`}>
            <div className="chart-Hdr">
                <h2 style={{ textAlign: "left" }}>Average Spend Per Site by Sector analysis{"  "}</h2>
                <div className="chart-fltr">
                    <select
                        style={{ width: "170px" }}
                        value={period}
                        onChange={(e) => changePeriodHandler(e)}
                    >
                        {options}
                    </select>
                </div>
            </div>
            {topSellerChartData && (
                <Line
                    data={topSellerChartData}
                    plugins={[noDataPlugin]}
                    options={{
                        responsive: true,
                        plugins: {
                            legend: {
                                display: true,

                            },
                            title: {
                                display: false,
                                text: "Average Spend Per Site by Sector analysis",
                            },
                            datalabels: {
                                display: false,
                                font: {
                                    weight: "bold",
                                },
                                anchor: "end",
                                align: "top",
                                formatter: function (value) {
                                    return "£" + value;
                                },
                            },
                            tooltip: {
                                callbacks: {
                                    title: (tooltipItems) => {
                                        /* for (let i = 0; i < tooltipItems.length; i++) {
                                             const index = tooltipItems[i].label;
                                             const sectorName = tooltipItems[i].dataset.label;
                                             return `${sectorName} ${index}`;
                                         } */
                                        const index = tooltipItems[0].label;

                                        return `Average Spend Per Site for ${index}`;
                                    },
                                    label: (tooltipItem) => {
                                        const sectorName = tooltipItem.dataset.label;
                                        return `${sectorName}: £${tooltipItem.formattedValue}`;

                                    },
                                },
                            },
                        },
                    }}
                />
            )}
        </div>
    );
});
