import React, { useState, useEffect } from 'react';
import Header from '../common/layout/header';
import Footer from '../common/layout/footer';
import { BaseUrl } from '../common/config';
import Sidebar from '../common/sidebar';
import { http } from '../helper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const AddPromotions = () => {
  const [inputFieldtitle, setInputFieldtitle] = useState('');
  const [bannerDesktop, setBannerDesktop] = useState(null); 
  const [bannerMobile, setBannerMobile] = useState(null);
  const [bannerDesktopPreview, setBannerDesktopPreview] = useState(null);
  const [bannerMobilePreview, setBannerMobilePreview] = useState(null);
  const [errormsg, setErrormsg] = useState({});
  const [errmessage, setErrMessage] = useState({});
  const [alertPopup, setAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState([]);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);  // For featured checkbox
  const [sortOrder, setSortOrder] = useState(1);  
  const [status, setStatus] = useState('active');   
  const [selectedCategory, setSelectedCategory] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  if (!sessionStorage.getItem("isLoggedIn")) {
    window.location.href = '/';
  }
  if (JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_promotions')) {
    window.location.href = '/unauthorized';
  }

  function cancelbtn(e) {
    e.preventDefault();
    window.location.href = '/promotions';
    return false;
  }

  const handleFromDateChange = (date) => {
    setFromDate(date);
    // if (date > toDate) {
    //   //setErrormsg({ ...errormsg,fromDateErr:'', toDateErr: "To Date cannot be before From Date!" });
    // } else {
      setErrormsg((prev) => ({ ...prev, fromDateErr: '' }));
      
    // }
  };

  const handleToDateChange = (date) => {
    if (date >= fromDate) {
      setToDate(date);
      setErrormsg({ ...errormsg, toDateErr: "" });
    } else {
      setErrormsg({ ...errormsg, toDateErr: "To Date cannot be before From Date!" });
    }
  };
  
  const handleRadioChange = (event) => {
    const { value } = event.target;
    setSelectedCategory(value);
    setErrormsg((prev) => ({ ...prev, categoryErr: '' }));
    };

  const formvalidation = () => {
    let newErrors = {};
    let isValid = true;
  
    if (!inputFieldtitle.trim()) {
      newErrors.inputFieldtitleerr = "This field is required";
      isValid = false;
    }
  
    if (!selectedCategory) {
      newErrors.categoryErr = "Please select a category";
      isValid = false;
    }

    if (!fromDate) {
      newErrors.fromDateErr = 'This field is required';
      isValid = false;
    }

    if (!toDate) {
      newErrors.toDateErr = 'This field is required';
      isValid = false;
    } else if (toDate < fromDate) {
      newErrors.toDateErr = "To Date cannot be before From Date!";
      isValid = false;
    }
  
    if (!bannerDesktop) {
      newErrors.desktopErr = "This field is required";
      isValid = false;
    }
  
    if (!bannerMobile) {
      newErrors.mobileErr = "This field is required";
      isValid = false;
    }
  
    setErrormsg(newErrors); 
    return isValid; 
  };
  
   const submitButton = (e) => {
    e.preventDefault();
    if (!formvalidation()) {
      return;
    }
      uploadFile();
  };

  const eventhandlechange = (e) => {
    const value = e.target.value || "";
    setInputFieldtitle(value);
    
    setErrormsg((prev) => ({
      ...prev,
      inputFieldtitleerr: value.trim() ? "" : prev.inputFieldtitleerr,
    }));
  };
  

  const handleFileUpload = (event, type) => {
    const file = event.target.files[0];
  
    if (!file) {
      return;
    }
        const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
        const fileType = file.type.toLowerCase(); 

        const fileSizeInBytes = file.size;
        const fileSizeInMB = (fileSizeInBytes / 1024 / 1024).toFixed(2); 
        const maxSizeMB = 2;
   
    let errorMessage = "";
  
    if (!allowedTypes.includes(fileType)) {
      errorMessage = "Only JPG, JPEG, and PNG files are allowed.";
    } else if (fileSizeInMB > maxSizeMB) {
      errorMessage = "File size must be less than 2MB.";
    }
  
    setErrormsg((prev) => ({
      ...prev,
      [`${type}Err`]: errorMessage, 
    }));
  
    if (errorMessage) {
      event.target.value = ""; 
      return;
    }
  
    setErrormsg((prev) => ({
      ...prev,
      [`${type}Err`]: "", 
    }));
  
    if (type === "desktop") {
      setBannerDesktop(file);
      setBannerDesktopPreview(URL.createObjectURL(file));
    } else {
      setBannerMobile(file);
      setBannerMobilePreview(URL.createObjectURL(file));
    }
  };
  
  
  const uploadFile = async () => {
    setIsLoading(true);
  
    try {
      const formData = new FormData();
      formData.append("Title", inputFieldtitle.trim());
      formData.append("Category", (selectedCategory));
      formData.append('fromDate', moment(fromDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
      formData.append('toDate', moment(toDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'));
      formData.append("InDownloads", isFeatured);
      formData.append("sortOrder", sortOrder);
      formData.append("Status", status);
      formData.append("bannerImageDesktop", bannerDesktop);
      formData.append("bannerImageMobile", bannerMobile);
      formData.append("createdBy", JSON.parse(sessionStorage.getItem("authData")).adminName);
      const response = await http.post(BaseUrl + 'admin/addpromotions', formData);
  
      if (response.data.success) {
        setIsLoading(false);
        setAlertPopup(true);
        setAlertMessage('Promotion Banner added successfully');
      }
    } catch (error) {
      setIsLoading(false);
      setAlertPopup(true);
      setAlertMessage("Error uploading images");
      console.error("Error:", error);
    }
  };

  const popupClose = (event) => {

    setVisibleAlert(false);
  }


  return (
  <section className="dashboard-block">
    {alertPopup && (
      <div className='custom-alert-pop common_alert_popup' style={{ display: "block" }}>
        <div className='custom-alert-container'>
          <div className='custom-alert-wrap'>
            <div className='custom-alert-header'>
              <h4>Alert</h4>
            </div>
            <div className='custom-alert-body'>
              <p>{alertMessage}</p>

              <div className='alert-buttons'>
                <a onClick={(e) => cancelbtn(e)} className="popup-alert-btn button">OK</a>
              </div>
            </div>
          </div>
        </div>
      </div>

    )}

    <Sidebar />

    <div className="dash-left">
      <Header />
      <section className="cart-block">
        <div className="container">
          <h1 className="checkout-title">Add New Promotion Banner</h1>

          <div className="right-table-outer-wrap">
          {(isLoading) ? (
            <div className='loading-buffer'></div>
          ) : (

            <form method='post' className="addform editform promo-form freeproduct-fieldset form-container-xs" onSubmit={submitButton}>

              <div className="input-field col-1">
                <label>Title</label>
                <input type="text" value={inputFieldtitle} name="inputFieldtitle" onChange={eventhandlechange} className="form-control" placeholder="Title" />
                {errormsg.inputFieldtitleerr && <div className="error-msg">{errormsg.inputFieldtitleerr}</div>}
              </div>

              <div className="input-field col-1">
          <label>Select the category for this Promotion Banner</label>
          <div className="edit-free-cat-chks">
              {["pizza", "pasta", "burrito"].map((category, index) => {
                  let displayCategory = category === "pasta" 
                      ? "Pasta, Rice & Noodle Sauces" 
                      : category.charAt(0).toUpperCase() + category.slice(1);
                  return (
                      <div className="check-box" key={index}>
                          <input
                              type="radio"
                              id={`freeCat${index}`}
                              name="category"
                              value={category}
                              checked={selectedCategory === category}
                              onChange={handleRadioChange}
                          />
                          <label htmlFor={`freeCat${index}`}>{displayCategory}</label>
                      </div>
                  );
              })}
          </div>
          {errormsg.categoryErr && <div className="error-msg">{errormsg.categoryErr}</div>}
      </div>

      <div className="input-field col-1">
        <label>Dates For the Promotion Banner</label>
      </div>
              <div className="input-field col-1">
                <label className='lbl-blk'>From Date</label>
                <DatePicker
                  selected={fromDate}
                  onChange= {handleFromDateChange}
                  minDate={new Date()}
                  placeholderText="Select From Date"
                  dateFormat='dd-MM-yyyy'
                  onKeyDown={(e) => {
                    e.preventDefault(); 
                }}
                  className="form-control"
                />
                {errormsg.fromDateErr && <div className="error-msg">{errormsg.fromDateErr}</div>}
              </div>

              <div className="input-field col-1">
                <label className='lbl-blk'>To Date</label>
                <DatePicker
                  selected={toDate}
                  onChange= {handleToDateChange}
                  minDate={new Date()}
                  placeholderText="Select To Date"
                  dateFormat='dd-MM-yyyy'
                  onKeyDown={(e) => {
                    e.preventDefault(); 
                }}
                  className="form-control"
                />
                {errormsg.toDateErr && <div className="error-msg">{errormsg.toDateErr}</div>}
              </div>

              <div className='notification-field-set-wrap'>
                <div className='notification-field-set promo-field-set'>
                  <label>Banner Image <span className="text-note">(Maximum Upload File Size: 2 MB)</span></label>
                  <div className="input-field col-1">
                        <label>Desktop</label>
                         
                        <div className="flex align-center gap-10">
                        <input 
                          type="file" 
                          id="desktop_banner" 
                          accept="image/png, image/jpeg, image/jpg" 
                          onChange={(e) => handleFileUpload(e, "desktop")} 
                        />
                        <span className="text-note">(.jpg, .jpeg, .png files only allowed)*<br /><br /></span>
                        {bannerDesktopPreview && <img src={bannerDesktopPreview} alt="Desktop Preview" width="150" />}
                        </div>
                        {errormsg.desktopErr && <div className="error-msg">{errormsg.desktopErr}</div>}
                        
                        </div>
                       
                  
                  <div className="input-field col-1">
                        <label>Mobile</label>
                        <div className="flex align-center gap-10">
                        <input 
                          type="file" 
                          id="mobile_banner" 
                          accept="image/png, image/jpeg, image/jpg" 
                          onChange={(e) => handleFileUpload(e, "mobile")} 
                        />
                        <span className="text-note">(.jpg, .jpeg, .png files only allowed)*<br /><br /></span>
                        {bannerMobilePreview && <img src={bannerMobilePreview} alt="Mobile Preview" width="150" />}
                       
                        </div>
                        {errormsg.mobileErr && <div className="error-msg">{errormsg.mobileErr}</div>}
                       
                      
                        
                  </div>
                </div>
            </div>
                         
              {/* Featured Checkbox */}
              <div className="input-field col-1">
              <label>Display for Downloads</label>
              <div className="check-box">
                  <input type="checkbox" id='setImg' className='check-box' checked={isFeatured} onChange={(e) => setIsFeatured(e.target.checked)} />
                  <label for='setImg'>Yes</label>
                 </div>
              </div>

              {/* Sorting Input */}
              <div className="input-field col-3">
                <label className='full-width'>Sort Order</label>
                <input type="number" value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} min="1" placeholder="Order (1 for top priority)" />
              </div>

                {/* Status Dropdown */}
              <div className="input-field col-1">
                <label className='full-width'>Status</label>
                <select className='col-4' value={status} onChange={(e) => setStatus(e.target.value)}>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>


              <button type='submit'>Submit</button> &nbsp;&nbsp;
              <button onClick={(e) => cancelbtn(e)}>Cancel</button>
            </form>)}
        </div>
        </div>
      </section>
      <Footer />
    </div>

    {visibleAlert === true &&
      <div className='custom-alert-pop' style={{ display: "block" }}>
        <div className='custom-alert-container'>
          <div className='custom-alert-wrap'>
            <div className='custom-alert-header'>
              <h4>Alert</h4>
            </div>
            <div className='custom-alert-body'>
              <p>File size must be less than 5 MB.</p>

              <div className='alert-buttons'>
                <a onClick={popupClose} className="popup-alert-btn button">OK</a>
              </div>
            </div>
          </div>
        </div>
      </div>}
  </section>);

}
export default AddPromotions;