import React, { Component} from 'react';
import Header from '../common/layout/header';
import Footer from '../common/layout/footer'; 
import DataTable from 'react-data-table-component';
import { BaseUrl } from '../common/config';
import Sidebar from '../common/sidebar';
import moment from 'moment';
import { http } from '../helper';
import { capitalizeFirstLetter,dateformatwithuktime } from "../utilityfunctions";
 
 
class Promotions extends Component {


    
    constructor(props) {
        super(props);
        this.state = {
            searchTextVal:"",
            promotionslist: [],
            templist :[],
            loading: true,
            popupView: "",
            visibleAlert:false,
            freeproductpdfvalue : '',
        };

        if(!sessionStorage.getItem("isLoggedIn")){
            window.location.href = '/';
        }
        if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_promotions'))
            {
              window.location.href = '/unauthorized';
            }
    
     }

    getPromotionslist() {
        var loginDetails = {};
            if (sessionStorage.getItem('authData')) {
               loginDetails = JSON.parse(sessionStorage.getItem('authData'));
            } 
        let URL = BaseUrl + "admin/listpromotions";
        http.get(URL).then((response) => {
                 // Sort by sortOrder
                 //let responseData = response.data.data.sort((a, b) => a.sortOrder - b.sortOrder);
                 let responseData = response.data.data;
                 responseData.sort((a, b) => {
                    let fromDateA = new Date(a.FromDate);
                    let fromDateB = new Date(b.FromDate);
                    let toDateA = new Date(a.ToDate);
                    let toDateB = new Date(b.ToDate);
        
                    // Sort by fromDate first
                    if (fromDateA - fromDateB !== 0) {
                        return fromDateA - fromDateB;
                    }
                    // If fromDate is the same, sort by toDate
                    return toDateA - toDateB;
                });
        
                this.setState({ promotionslist: responseData, loading: false, templist: responseData });
            //this.setState({ promotionslist: responseData, loading: false, templist:responseData});
        }).catch((err) => {
            console.log(err);
        })
    }

   

    editPromotionDetails = (editid)=>{
       
        window.location.href=`/promotions/edit/${editid}`;
       
      };
    deletePromotion =(value) =>
    {
    this.setState({visibleAlert:true,freeproductpdfvalue:value});
    }
    deletePromotionsdetails = () =>
    {
        var loginDetails = {};
            if (sessionStorage.getItem('authData')) {
               loginDetails = JSON.parse(sessionStorage.getItem('authData'));
            } 
        let URL = BaseUrl + `admin/deletepromotion/${this.state.freeproductpdfvalue._id}`;  
        http.delete(URL).then(async (response) => {
            
            if(response.data.success === true){

                window.location.reload();
            }

        
        }).catch((err) => {
            console.log(err);
            
        });  


      }

      alertPopupclose = () =>
      {
          this.setState({visibleAlert:false,freeproductvalue:''});
      }

    componentDidMount() {
        this.getPromotionslist();
    }

    addPromotion = () =>
      {
        window.location.href='/promotions/add'
      }
    
    handleReset=()=>{
        this.setState({searchTextVal:'',promotionslist:this.state.templist});
     };

    handleChange = async(value) => {
        this.setState({searchTextVal:value});
        this.filterData(value);
      };
           

      filterData = async(value) => {

        let accoun = this.state.templist;
      
        const lowercasedValue = value.toLowerCase().trim();
        let result = [];
        if (lowercasedValue.length>0){
            accoun.map((item)=>{
            if(item.Title.toString().toLowerCase().includes(lowercasedValue))
            {
              result.push(item);
            }
          })
          this.setState({promotionslist:result});
        }else {
        this.setState({promotionslist:accoun});
      }
      };


    render() {

        const columns = [
            {
                name: "Title",
                selector: row => row.Title,
                sortable: true,
            },
            {
                name: "Category",
                selector: row =>(row.Category==='pasta'?'Pasta, Rice & Noodle Sauces':row.Category.charAt(0).toUpperCase() + row.Category.slice(1)),
                sortable: true,
            },
           
            {
                name: "Status",
                selector: row =>capitalizeFirstLetter(row.Status),
                sortable: true,
            }, 
            // {
            //     name: "Sort Order",
            //     selector: row =>row.sortOrder,
            //     sortable: true,
            // },
            {
                name: "From Date",
                selector: row => moment(row.FromDate).format("DD-MM-YYYY"), 
                sortable: true,
            },
            {
                name: "To Date",
                selector: row => moment(row.ToDate).format("DD-MM-YYYY"), 
                sortable: true,
            },    
            {
                name: "Actions",
                selector: row => <div style={{display:"flex"}}>
                    <a className='editbtn button title-tooltip title-up' data-title='Edit' style={{color: "white"}} onClick={()=>this.editPromotionDetails(row._id)}><i className="fa fa-pencil" aria-hidden="true"></i></a>
                    <a className='editbtn button title-tooltip title-up' data-title='Delete' style={{color: "white"}} onClick={()=>this.deletePromotion(row)}><i className="fa fa-trash" aria-hidden="true"></i></a>  
                   </div>,
                sortable: false,
            }
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '25px',
                },
            },
            headCells: {
                style: {
                    padding: '8px', // override the cell padding for head cells
                    
                },
            },
            cells: {
                style: {
                    padding: '8px', // override the cell padding for data cells
                    
                },
            },
        };

        const paginationComponentOptions = {
            rowsPerPageText: '',
            rangeSeparatorText: '',
            selectAllRowsItem: true,
            selectAllRowsItemText: '',
            noRowsPerPage:'',
        };
        const conditionalRowStyles = [
            {
                when: row => moment(row.ToDate, "YYYY-MM-DD").isBefore(moment(), 'day'),
                style: {
                    backgroundColor: "#ffcccc !important", // Light red for expired rows
                    color: "#000", // Optional: Adjust text color for visibility
                },
            },
        ];
        

        return (
            <>
                <div className="dashboard-block">

                        <Sidebar />
                 
                    <div className="dash-left">
                        <Header />

                        <section className="cart-block">
                        <div className="container">
                            <h1 className="checkout-title">Promotion Banner</h1>  

                            <div className="right-table-outer-wrap">
                            <div className="table-filter search-orders">
                              <div className='table-search-reset'>
                                <div className="table-search">
                                    <input type="text" value={this.state.searchTextVal} onChange={e => this.handleChange(e.target.value)} className="form-control" placeholder="Search by Title" />
                                    
                                </div>
                                <button className='submitbtn button search-ord-btn' onClick={e => this.filterData(this.state.searchTextVal)}><i className="fa fa-search"></i></button>
                                 
                                <button className='resetbtn' type='reset' onClick={this.handleReset}>Reset</button>
                              </div>
                             
                              <div className='add-holidaybtn'>
                                <button className='addbtn' onClick={this.addPromotion}>Add</button>
                              </div>
                            </div>
                            
                            <div className="table_overflow promotions-data-table">
                           
                                    <DataTable
                                        columns={columns}
                                        noDataComponent={<div className='no-records-found'>Sorry, there are no promo banner matching your keyword, try a new search.</div>}
                                        data={this.state.promotionslist}
                                        customStyles={customStyles}
                                        pagination
                                        paginationPerPage={25}
                                        progressPending={this.state.loading}
                                        paginationRowsPerPageOptions={[25, 50, 75, 100]}
                                        paginationComponentOptions={paginationComponentOptions }
                                        conditionalRowStyles={conditionalRowStyles} // Apply row highlighting
                                    />
                                    
                            </div>
                        </div>
                        </div>
                        </section>

                        <Footer />
                    </div>
                </div>

                {this.state.visibleAlert === true && 
                <div className='custom-alert-pop paylater-popup'>
                    <div className='custom-alert-container'>
                        <div className='custom-alert-wrap'>
                            <div className='custom-alert-header'>
                                <h4>Alert</h4>
                            </div>
                            <div className='custom-alert-body'>
                                <p>Are you sure you want to delete?</p>

                                <div className='alert-buttons'>

                                    <a onClick={this.deletePromotionsdetails.bind(this)} className="popup-alert-btn button" >OK</a>
                                    <a onClick={this.alertPopupclose.bind(this)} className="popup-alert-btn button">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>} 

            </>
        );
    }
}

export default Promotions;